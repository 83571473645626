@import "@alltrails/shared/styles/landsEnd.scss";
.container {
  text-align: center;
  padding-top: 100px;
  padding-left: 12px;
  padding-right: 12px;
  min-height: calc(100dvh - var(--size-header-height-desktop));
  width: 100vw;
  background-color: $color-background-primary;

  @include medium-up {
    padding-top: 180px;
  }
}

.error {
  padding-top: 40px;
  opacity: 0.5;
}

.header {
  padding-top: 8px;
}

.textContainer {
  text-align: center;
  margin: 0 auto;
  max-width: 475px;
}

.text {
  padding-top: 8px;
}

.buttonContainer {
  padding-top: 20px;
}
