@import "@alltrails/shared/styles/landsEnd.scss";
.mb8 {
  margin-bottom: $space-8;
}

.mb12 {
  margin-bottom: $space-12;
}

.mb16 {
  margin-bottom: $space-16;
}

.mb24 {
  margin-bottom: $space-24;
}

.mb32 {
  margin-bottom: $space-32;
}

.mb48 {
  margin-bottom: $space-48;
}

.mb64 {
  margin-bottom: $space-64;
}

.mt2xl {
  margin-top: $space-32;
}

.heading100 {
  @include heading-100;
}

.heading200 {
  @include heading-200;
}

.heading300 {
  @include heading-300;
}

.heading400 {
  @include heading-400;
}

.heading500 {
  @include heading-500;
}

.heading600 {
  @include heading-600;
}

.heading700 {
  @include heading-700;
}

.paragraph100 {
  @include paragraph-100;
}

.paragraph200 {
  @include paragraph-200;
}

.paragraph300 {
  @include paragraph-300;
}

.text100 {
  @include text-100;
}

.text100bold {
  @include text-100-bold;
}

.text200 {
  @include text-200;
}

.text200bold {
  @include text-200-bold;
}

.text300 {
  @include text-300;
}

.text300bold {
  @include text-300-bold;
}

// css specificity this needs to go last
.secondary {
  color: var(--color-text-secondary);
}

.error {
  color: var(--color-text-error);
}

.inverse {
  color: var(--color-text-primary-inverse);
}

.primary {
  color: var(--color-text-primary);
}

.tertiary {
  color: var(--color-neutral-500);
}
